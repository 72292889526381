let PageEditComponent = function (
    $state,
    $stateParams,
    PageService,
    MediaService,
    CategoryService,
    LanguageService,
    BlockTypeService,
    BlockGroupService,
    ModalService,
    BadgeService,
    SplashService,
    ReviewCategoryService,
    FormBuilderService,
    ProductCategoryService,
    $timeout
) {
    let $ctrl = this;

    $ctrl.media;
    $ctrl.blockTypes;
    $ctrl.groupTypes;
    $ctrl.pages;
    $ctrl.badges;
    $ctrl.category_products;
    $ctrl.splashes;
    $ctrl.categories;
    $ctrl.languages;
    $ctrl.language = 'nl';
    $ctrl.debug = false;

    $ctrl.$onInit = function () {
        $ctrl.states = PageService.states();

         CategoryService.list().then((res) => {
             $ctrl.categories = res.data.data;
         })

        BlockTypeService.list().then((res) => {
            $ctrl.blockTypes = res.data.data;
        });

        BlockGroupService.list().then((res) => {
            $ctrl.groupTypes = res.data.data;
        });

        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;
        });

        BadgeService.list().then((res) => {
            $ctrl.badges = res.data.data.map(badge => {
                badge.id_str = badge.id += '';

                return badge;
            });

            $ctrl.badges.unshift({
                id: null,
                id_str: "null",
                'name': 'Select badge...'
            });
        });

        SplashService.list().then((res) => {
            $ctrl.splashes = res.data.data.map(splash => {
                splash.id_str = splash.id += '';

                return splash;
            });

            $ctrl.splashes.unshift({
                id: null,
                id_str: "null",
                'name': 'Select splash...'
            });
        });

        ProductCategoryService.list().then((res) => {
            $ctrl.category_products = res.data.data.map(category => {
                category.id_str = category.id += '';

                return category;
            });

            $ctrl.category_products.unshift({
                id: null,
                id_str: "null",
                'name': 'Select category...'
            });
        });

        /*ReviewCategoryService.list().then((res) => {
            $ctrl.categories = res.data.data.map(category => {
                category.id_str = category.id += '';

                return category;
            });
        });*/

        PageService.list().then((res) => {
            $ctrl.pages = res.data.data.map(page => {
                page.id_str = page.id += '';

                return page;
            });

            $ctrl.pages.unshift({
                id: null,
                id_str: "null",
                'title': 'Select page...'
            });

            $ctrl.pagesForParent = $ctrl.pages.filter(page => 
                page.id != $ctrl.pageId 
            );

            $ctrl.blocks.forEach((block) => {
                let title = block.block_type.name,
                    titleField = null,
                    headingFields = [];

                if (block.block_field_values) {
                    titleField = block.block_field_values.filter(blockField => {
                        return blockField.block_type_field.key == 'title';
                    })[0];

                    headingFields = block.block_field_values.filter(blockField => {
                        return blockField.block_type_field.type == 'heading_text';
                    });

                    headingFields.forEach(function(headingField) {
                        let fieldId = headingField.block_type_field_id;
                        let markdownSymbols = '';

                        if (headingField.value.match(/#+/)) {
                            markdownSymbols = headingField.value.match(/#+/)[0] + ' ';
                        }

                        block.extra_values = {};
                        block.extra_values[fieldId] = markdownSymbols;

                        $ctrl.languages.forEach((language) => {
                            if (block.values[fieldId][language.locale]) {
                                let value = block.values[fieldId][language.locale];
    
                                value = value.replace(markdownSymbols, '');
    
                                block.values[fieldId][language.locale] = value;
                            }
                        });
                    });
                }

                block.block_title = titleField ? titleField.value + ' (' + title + ') ' : title;
            });
        });

        if ($ctrl.pageId) {
            $ctrl.language = PageService.getEditorLocale($ctrl.pageId);

            return PageService.read($ctrl.pageId).then((res) => {
                $ctrl.page = res.data.data;
                $ctrl.loadPage($ctrl.page);
            });
        }

        $ctrl.page = false;

        $ctrl.loadPage({
            state: $ctrl.states[0].id,
            type: 'page',
            parent_id: null
        });
    };

    $ctrl.onEditorLocaleChanged = (locale) => {
        PageService.setEditorLocale($ctrl.pageId, locale);
    }

    $ctrl.syncFieldValues = (block, blockTypeField) => {
        let blockFieldValues = block.values[blockTypeField];

        if (Array.isArray(blockFieldValues)) {
            blockFieldValues = {};
        }

        if (!blockFieldValues) {
            return;
        }

        let value = blockFieldValues[$ctrl.language];

        $ctrl.languages.forEach((language) => {
            if (language.locale != $ctrl.language) {
                blockFieldValues[language.locale] = value;
            }
        });

        block.values[blockTypeField] = blockFieldValues;
    }

    $ctrl.setFieldValue = (block, blockTypeField, value) => {
        block.values[blockTypeField] = {};

        block.values[blockTypeField][$ctrl.language] = value;

        $ctrl.syncFieldValues(block, blockTypeField);
    }

    $ctrl.deleteBlock = (block) => {
        let blockIndex = $ctrl.blocks.indexOf(block);

        if (blockIndex != -1) {
            $ctrl.blocks.splice(blockIndex, 1)
        }
    };

    $ctrl.buildBlock = (block_type_id) => {
        $ctrl.formAddBlock = {};

        $ctrl.formAddBlock[block_type_id] = FormBuilderService.build({
            block_type_id: $ctrl.groupTypes[0].group_blocks[0].id,
            values: {},
        }, (form) => {
            form.values.block_type = {};

            $ctrl.groupTypes.forEach((category) => {
                let result = category.group_blocks.filter(
                    blockType => blockType.id == block_type_id
                );

                if(result && result.length){
                    form.values.block_type = result[0];
                }
            });

            form.values.block_type.block_type_fields.forEach((field) => {
                if (!form.values.extra_values) {
                    form.values.extra_values = {};
                }

                if (field.type == "heading_text") {
                    form.values.extra_values[field.id] = '## ';
                }

                if (field.type == "alignment") {
                    form.values.values[field.id] = {};

                    $ctrl.languages.forEach(language => {
                        form.values.values[field.id][language.locale] = 'center';
                    });
                }

                if (field.type.endsWith('_id')) {
                    form.values.values[field.id] = {};

                    $ctrl.languages.forEach(language => {
                        form.values.values[field.id][language.locale] = "null";
                    });
                }
            });

            $ctrl.blocks.push(form.values);
        });
    }

    $ctrl.addBlock = ($event) => {
        let groupTypes = angular.copy($ctrl.groupTypes);

        ModalService.open('blockAdd', {
            groupTypes: groupTypes,
            success: (data) => {
                data.sel_block_types.forEach(function (block_type){
                    $ctrl.buildBlock(block_type.id);
                    $ctrl.formAddBlock[block_type.id].submit();
                });
            }
        });

        $event.preventDefault();
        $event.stopPropagation();
    };

     $ctrl.toggleCategory = (id) => {
         if (!Array.isArray($ctrl.form.values.categories)) {
             $ctrl.form.values.categories = [];
         }

         let index = $ctrl.form.values.categories.indexOf(id);

         if (index == -1) {
             $ctrl.form.values.categories.push(id);
         } else {
             $ctrl.form.values.categories.splice(index, 1);
         }
     };

    $ctrl.loadPage = (page) => {
        let values = PageService.apiResourceToForm(page);

        $ctrl.form = FormBuilderService.build(values, (form) => {
            form.lock();

            let blockValues = [];

            $ctrl.blocks.forEach(block => {
                let blockValuesTmp = angular.copy(block.values);

                //Workaround to save values like headings (ex '### some-text')
                //block.extra_values contains such values
                for (let extraValue in block.extra_values) {

                    $ctrl.languages.forEach((language) => {
                        if(blockValuesTmp[extraValue]){

                            let currentFieldValue = blockValuesTmp[extraValue][language.locale];

                            if (currentFieldValue) {
                                blockValuesTmp[extraValue][language.locale] = 
                                    block.extra_values[extraValue] + currentFieldValue;
                            }
                        }
                    });

                }

                blockValues.push({
                    id: block.id,
                    media_id: block.media_id || null,
                    media_ids: block.media_ids || [],
                    block_type_id: block.block_type.id,
                    values: blockValuesTmp,
                    extra_values: block.extra_values
                });
            });

            form.values.blocks = blockValues;

            let promise;

            if ($ctrl.page) {
                promise = PageService.update(
                    $ctrl.page.id,
                    form.values
                )
            } else {
                promise = PageService.store(
                    form.values
                )
            }

            promise.then((res) => {
                if (res.data.data && res.data.data.url) {
                    if($ctrl.page && $ctrl.page.redirect){
                        document.location = res.data.data.url.admin_list;
                    } else if(!$ctrl.page){
                        pushNotifications.push('success', 'check', 'Saved', 4000);

                        $timeout(function() {
                            document.location = res.data.data.url.admin_edit;
                        }, 1000);
                    } else {
                        pushNotifications.push('success', 'check', 'Saved', 4000);
                    }
                }

                form.unlock();
            }, (res) => {
                form.errors = res.data.errors;
                form.unlock();

                $timeout(function() {
                    let topPositions = [],
                        topPosition = 0;

                    angular.element('.form-error').each( (index, error_element) => {
                        topPositions.push(angular.element(error_element).offset().top);
                    });

                    topPositions.sort();
                    topPosition = topPositions[0];

                    angular.element(document).scrollTop(topPosition - 80);
                }, 1000);
            });
        });

        if (page) {
            if (page.featured_image) {
                MediaService.read(page.featured_image.uid).then((res) => {
                    $ctrl.media = res.data.data;
                });
            }

            if (page.blocks) {
                if (!$ctrl.debug) {
                    $ctrl.blocks = page.blocks;
                    $ctrl.toggleCollapse($ctrl.blocks);
                } else {
                    $ctrl.blocks = page.blocks.map(block => {
                        if (block.block_type.block_type_fields.length > 0 || (
                            block.block_type.has_media || block.block_type.has_medias
                        )) {
                            block.opened = true;
                        }

                        return block;
                    });
                }
            }
        }

        if (!Array.isArray($ctrl.blocks)) {
            $ctrl.blocks = [];
        }
    };

    // sortable settings for bots, options and controls
    $ctrl.sortable = {
        sort: true,
        animation: 150,
        handle: '.block-header-sortable',
        group: 'block-headers',
        me: function () {
            this.disabled = true;
        },
        ml: function () {
            this.disabled = false;
        }
    };

    $ctrl.deletePhoto = () => {
        delete $ctrl.media;
        $ctrl.form.values.media_id = null;
    };

    $ctrl.selectPhoto = (media) => {
        $ctrl.media = media;
        $ctrl.form.values.media_id = $ctrl.media.id;
    };

    $ctrl.selectPhotoBlockSingle = (block, media) => {
        block.media_id = media.id;
    };

    $ctrl.deletePhotoBlockSingle = (block) => {
        delete block.media;
        block.media_id = null;
    };

    $ctrl.selectPhotoBlockMulti = (block, media_ids) => {
        block.media_ids = media_ids;
    };

    $ctrl.selectFieldPhotoSingle = (block, blockTypeFieldId, media) => {
        $ctrl.setFieldValue(block, blockTypeFieldId, media);
    };

    $ctrl.deleteFieldPhotoSingle = (block, blockTypeFieldId) => {
        $ctrl.setFieldValue(block, blockTypeFieldId, null);
    };

    $ctrl.toggleCollapse = (blocks) => {
        if (blocks.filter(block => block.opened).length > 0) {
            blocks.forEach(block => block.opened = false);
        } else {
            blocks.forEach(block => block.opened = true);
        }
    };

    $ctrl.cancel = function () {
        $state.go('pages', {
            'organization_id': $stateParams.organization_id
        });
    };
};

module.exports = {
    bindings: {
        'pageId': '<'
    },
    controller: [
        '$state',
        '$stateParams',
        'PageService',
        'MediaService',
        'CategoryService',
        'LanguageService',
        'BlockTypeService',
        'BlockGroupService',
        'ModalService',
        'BadgeService',
        'SplashService',
        'ReviewCategoryService',
        'FormBuilderService',
        'ProductCategoryService',
        '$timeout',
        PageEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/page-edit.html'
};