const app = angular.module('forusApp', [
    'ui.router', 'pascalprecht.translate', 'ngCookies', 'uiCropper', 'ngLocale', 'ng-sortable', 'colorpicker', 'ngSanitize'
]);
const appConfigs = {
    api_url: "/api/"
};

app.constant('appConfigs', appConfigs);

// Controllers
app.controller('BaseController', require('./controllers/BaseController'));

// Components
app.component('pageEditComponent', require('./components/Pages/PageEditComponent'));
app.component('postEditComponent', require('./components/Pages/PostEditComponent'));
app.component('menuEditComponent', require('./components/Pages/MenuEditComponent'));
app.component('badgeEditComponent', require('./components/Pages/BadgeEditComponent'));
app.component('reviewCategoryEditComponent', require('./components/Pages/ReviewCategoryEditComponent'));
app.component('translationEditComponent', require('./components/Pages/TranslationEditComponent'));
app.component('uploadFileComponent', require('./components/Pages/UploadFileComponent'));
app.component('verticalAlignComponent', require('./components/Pages/VerticalAlignComponent'));
app.component('paginationComponent', require('./components/PaginationComponent'));
app.component('reviewEditComponent', require('./components/Pages/ReviewEditComponent'));
app.component('categoryEditComponent', require('./components/Pages/CategoryEditComponent'));
app.component('productCategoryEditComponent', require('./components/Pages/ProductCategoryEditComponent'));
app.component('productEditComponent', require('./components/Pages/ProductEditComponent'));
app.component('attributeEditComponent', require('./components/Pages/AttributeEditComponent'));
app.component('productCharacteristicEditComponent', require('./components/Pages/ProductCharacteristicEditComponent'));

// Modal Components
app.component('modalPhotoUploaderComponent', require('./components/Modals/ModalPhotoUploaderComponent'));
app.component('modalMediaEditComponent', require('./components/Modals/ModalMediaEditComponent'));
app.component('modalFileEditComponent', require('./components/Modals/ModalFileEditComponent'));
app.component('modalPromptComponent', require('./components/Modals/ModalPromptComponent.js'));
app.component('modalMarkdownCustomLinkComponent', require('./components/Modals/ModalMarkdownCustomLinkComponent'));
app.component('modalMarkdownPageLinkComponent', require('./components/Modals/ModalMarkdownPageLinkComponent'));
app.component('modalBlockComponent', require('./components/Modals/ModalBlockComponent'));
app.component('modalLoadLibraryComponent', require('./components/Modals/ModalLoadLibraryComponent'));

// Services
app.service('PageService', require('./services/PageService'));
app.service('MenuService', require('./services/MenuService'));
app.service('FileService', require('./services/FileService'));
app.service('MediaService', require('./services/MediaService'));
app.service('BadgeService', require('./services/BadgeService'));
app.service('ReviewCategoryService', require('./services/ReviewCategoryService'));
app.service('ModalService', require('./services/ModalService'));
app.service('CategoryService', require('./services/CategoryService'));
app.service('LanguageService', require('./services/LanguageService'));
app.service('BlockTypeService', require('./services/BlockTypeService'));
app.service('PaginationService', require('./services/PaginationService'));
app.service('BlockGroupService', require('./services/BlockGroupService'));
app.service('TranslationService', require('./services/TranslationService'));
app.service('FormBuilderService', require('./services/FormBuilderService'));
app.service('LocalStorageService', require('./services/LocalStorageService'));
app.service('ImageConvertorService', require('./services/ImageConvertorService'));
app.service('ReviewService', require('./services/ReviewService'));
app.service('SplashService', require('./services/SplashService'));
app.service('ProductCategoryService', require('./services/ProductCategoryService'));
app.service('ProductService', require('./services/ProductService'));
app.service('AttributeService', require('./services/AttributeService'));
app.service('ProductCharacteristicService', require('./services/ProductCharacteristicService'));

// Directives
app.directive('i18n', require('./directives/I18nDirective'));
app.directive('markdown', require('./directives/MarkdownDirective'));
app.directive('collapse', require('./directives/CollpaseDirective'));
app.directive('multiSelect', require('./directives/MultiSelectDirective'));
app.directive('clickOutside', require('./directives/ClickOutsideDirective'));
app.directive('photoSelector', require('./directives/PhotoSelectorDirective'));
app.directive('photosSelector', require('./directives/PhotosSelectorDirective'));
app.directive('filesSelector', require('./directives/FilesSelectorDirective'));
app.directive('formLabelToggle', require('./directives/FormLabelToggleDirective'));
app.directive('preventPropagation', require('./directives/PreventPropagation'));
app.directive('inputCheckboxControl', require('./directives/InputCheckboxControlDirective'));
app.directive('fadeIn', require('./directives/FadeInDirective'));
app.directive('productCategoriesControl', require('./directives/ProductCategoriesControlDirective'));

app.directive('openMediaModal', require('./directives/OpenMediaModal'));
app.directive('openFileModal', require('./directives/OpenFileModal'));

app.directive('paginator', require('./directives/paginators/PaginatorDirective'));
app.directive('paginatorLoader', require('./directives/paginators/PaginatorLoaderDirective'));

app.directive('modalsRoot', require('./directives/modals/ModalsRootDirective'));
app.directive('modalItem', require('./directives/modals/ModalItemDirective'));
app.directive('modalScrollBraker', require('./directives/modals/ModalScrollBrakerDirective'));
app.directive('linkConfirmation', require('./directives/LinkConfirmationDirective'));

// Providers
app.provider('ApiRequest', require('./providers/ApiRequestProvider'));
app.provider('ModalRoute', require('./providers/ModalRouteProvider'));
app.provider('I18nLib', require('./providers/I18nLibProvider'));

// Filters
app.filter('i18n', require('./filters/I18nFilter'));
app.filter('nl2br', require('./filters/nl2brFilter'));
app.filter('to_fixed', require('./filters/ToFixedFilter'));
app.filter('file_size', require('./filters/FileSizeFilter'));
app.filter('pretty_json', require('./filters/PrettyJsonFilter'));
app.filter('to_upper_case', require('./filters/ToUpperCaseFilter'));
app.filter('currency_format', require('./filters/CurrencyFormatFilter'));

// Config
app.config(require('./routers/modals'));
app.config(require('./routers/router'));
app.config(require('./config/api-service'));
app.config(require('./config/i18n'));
app.config(['$compileProvider', function($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|javascript):/);
}])

app.run(require('./routers/router-transitions'));

// Bootstrap the app
angular.bootstrap(document.querySelector('html'), ['forusApp', '720kb.datepicker']);

/* if (!appConfigs.html5ModeEnabled) {
    let hash = document.location.hash;

    if (hash.length > 3 && hash[hash.length - 1] == '/') {
        document.location.hash = hash.slice(0, hash.length - 1);
    } else if (hash.length < 3) {
        document.location.hash = '#!/';
    }
} */

module.exports = app;