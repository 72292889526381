let ProductService = function(ApiRequest) {
    let uriPrefix = '/products';

    return new (function() {
        this.list = function() {
            return ApiRequest.get(uriPrefix);
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                this.apiFormToResource(values)
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        };

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.apiFormToResource = function(formData) {
            let values = JSON.parse(JSON.stringify(formData));

            values.translations = values.translations || [];
            Object.assign(values, values.translations.nl || {});

            return values;
        };

        this.apiResourceToForm = function(apiResource) {
            return {
                translations: apiResource.translations,
                name: apiResource.name,
                slug: apiResource.slug,
                active: apiResource.active,
                description: apiResource.description,
                price: apiResource.price,
                category_id: apiResource.category_id,
                medias: apiResource.medias,
                media_ids: apiResource.media_ids,
                //attributes: apiResource.attributes,
                characteristics: apiResource.characteristics
            };
        };
    });
};

module.exports = [
    'ApiRequest',
    ProductService
];