let ProductCategoryEditComponent = function(
    ProductCategoryService,
    LanguageService,
    FormBuilderService,
    $timeout,
) {
    let $ctrl = this;

    $ctrl.productCategory;
    $ctrl.languages;
    $ctrl.language = 'nl';

    let loadResources = () => {
        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;
        });

        if ($ctrl.productCategoryId) {
            return ProductCategoryService.read($ctrl.productCategoryId).then((res) => {
                $ctrl.productCategory = res.data.data;
                $ctrl.loadProductCategory($ctrl.productCategory);
            });
        }

        $ctrl.productCategory = false;

        $ctrl.loadProductCategory({});
    };

    $ctrl.submit = () => {
        
    }

    $ctrl.loadProductCategory = function(productCategory) {
        let values = ProductCategoryService.apiResourceToForm(productCategory);

        $ctrl.form = FormBuilderService.build(values, (form) => {
            form.lock();

            let promise;

            if ($ctrl.productCategory) {
                promise = ProductCategoryService.update(
                    $ctrl.productCategory.id,
                    form.values
                )
            } else {
                promise = ProductCategoryService.store(
                    form.values
                )
            }

            promise.then((res) => {
                if (res.data.data) {
                    pushNotifications.push('success', 'check', 'Saved', 4000);

                    $timeout(function() {
                        document.location = res.data.data.url.admin_list;
                    }, 1000);
                }

                form.unlock();
            }, (res) => {
                form.errors = res.data.errors;
                form.unlock();
            });
        });

        if (!Array.isArray($ctrl.blocks)) {
            $ctrl.blocks = [];
        }
    }

    $ctrl.$onInit = function() {
        $ctrl.productCategory = false;
        
        loadResources();
    };
};

module.exports = {
    bindings: {
        'productCategoryId': '<'
    },
    controller: [
        'ProductCategoryService',
        'LanguageService',
        'FormBuilderService',
        '$timeout',
        ProductCategoryEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/product-category-edit.html'
};