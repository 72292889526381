let ProductEditComponent = function(
    ProductService,
    ProductCategoryService,
    LanguageService,
    FormBuilderService,
    AttributeService,
    ProductCharacteristicService,
    $timeout,
) {
    let $ctrl = this;

    $ctrl.productCategories;
    $ctrl.product;
    $ctrl.products;
    $ctrl.languages;
    $ctrl.attributes = [];
    $ctrl.characteristics = [];
    $ctrl.medias = [];
    $ctrl.language = 'nl';

    let loadResources = () => {
        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;
        });

        ProductCategoryService.list().then((res) => {
            $ctrl.productCategories = res.data.data;
        });

        ProductService.list().then((res) => {
            $ctrl.products = res.data.data;
        });

        ProductCharacteristicService.list().then((res) => {
            $ctrl.characteristics = res.data.data;
        });

        if ($ctrl.productId) {
            return ProductService.read($ctrl.productId).then((res) => {
                $ctrl.product = res.data.data;
                $ctrl.medias = $ctrl.product.medias;
                $ctrl.loadProduct($ctrl.product);
            });
        }

        $ctrl.product = false;

        $ctrl.loadProduct({});
    };

    $ctrl.submit = () => {
        
    };

    $ctrl.addAttribute = (attribute) => {
        attribute.items.push({
            name: null
        });
    };

    $ctrl.deleteAttributeItem = (e, parent, attribute) => {
        if (Array.isArray(parent.items)) {
            let index = parent.items.indexOf(attribute);

            if (index != -1) {
                parent.items.splice(index, 1);
            }
        }
    };

    $ctrl.loadProduct = function(product) {
        let values = ProductService.apiResourceToForm(product);

        AttributeService.list().then((res) => {
            $ctrl.attributes = res.data.data;

            $ctrl.attributes.forEach((attribute) => {
                let items = $ctrl.product
                    ? $ctrl.product.attributes.filter(productAttribute => {
                        return attribute.id == productAttribute.id;
                    })[0]
                    : null;

                attribute.items = items ? items.items : [];
            });
        });

        $ctrl.form = FormBuilderService.build(values, (form) => {
            form.lock();

            let promise;

            form.values.attributes = $ctrl.attributes;

            if ($ctrl.product) {
                promise = ProductService.update(
                    $ctrl.product.id,
                    form.values
                )
            } else {
                promise = ProductService.store(
                    form.values
                )
            }

            promise.then((res) => {
                if (res.data.data) {
                    pushNotifications.push('success', 'check', 'Saved', 4000);

                    $timeout(function() {
                        document.location = res.data.data.url.admin_list;
                    }, 1000);
                }

                form.unlock();
            }, (res) => {
                form.errors = res.data.errors;
                form.unlock();
            });
        });

        if (!Array.isArray($ctrl.blocks)) {
            $ctrl.blocks = [];
        }
    };

    $ctrl.$onInit = function() {
        $ctrl.productCategories = false;
        $ctrl.product = false;
        
        loadResources();
    };

    $ctrl.selectPhotoBlockMulti = (media_ids) => {
        $ctrl.media_ids = media_ids;
        $ctrl.form.values.media_ids = media_ids;
    };
};

module.exports = {
    bindings: {
        'productId': '<'
    },
    controller: [
        'ProductService',
        'ProductCategoryService',
        'LanguageService',
        'FormBuilderService',
        'AttributeService',
        'ProductCharacteristicService',
        '$timeout',
        ProductEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/product-edit.html'
};