let CategoryEditComponent = function(
    CategoryService,
    LanguageService,
    FormBuilderService,
    $timeout,
) {
    let $ctrl = this;

    $ctrl.categories;
    $ctrl.category;
    $ctrl.languages;
    $ctrl.language = 'nl';

    let loadResources = () => {
        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;
        });

        if ($ctrl.categoryId) {
            return CategoryService.read($ctrl.categoryId).then((res) => {
                $ctrl.category = res.data.data;
                $ctrl.loadCategory($ctrl.category);
            });
        }

        $ctrl.category = false;

        $ctrl.loadCategory({});
    };

    $ctrl.submit = () => {
        
    };

    $ctrl.loadCategory = function(category) {
        let values = CategoryService.apiResourceToForm(category);

        $ctrl.form = FormBuilderService.build(values, (form) => {
            form.lock();

            let promise;

            if ($ctrl.category) {
                promise = CategoryService.update(
                    $ctrl.category.id,
                    form.values
                )
            } else {
                promise = CategoryService.store(
                    form.values
                )
            }

            promise.then((res) => {
                if (res.data.data) {
                    pushNotifications.push('success', 'check', 'Saved', 4000);

                    $timeout(function() {
                        document.location = res.data.data.url.admin_list;
                    }, 1000);
                }

                form.unlock();
            }, (res) => {
                form.errors = res.data.errors;
                form.unlock();
            });
        });

        if (!Array.isArray($ctrl.blocks)) {
            $ctrl.blocks = [];
        }
    }

    $ctrl.$onInit = function() {
        $ctrl.categories = false;
        $ctrl.category = false;
        
        loadResources();
    };
};

module.exports = {
    bindings: {
        'categoryId': '<'
    },
    controller: [
        'CategoryService',
        'LanguageService',
        'FormBuilderService',
        '$timeout',
        CategoryEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/category-edit.html'
};