let ProductCharacteristicEditComponent = function(
    ProductCharacteristicService,
    LanguageService,
    FormBuilderService,
    $timeout,
) {
    let $ctrl = this;

    $ctrl.productCharacteristic;
    $ctrl.languages;
    $ctrl.language = 'nl';

    let loadResources = () => {
        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;
        });

        if ($ctrl.productCharacteristicId) {
            return ProductCharacteristicService.read($ctrl.productCharacteristicId).then((res) => {
                $ctrl.productCharacteristic = res.data.data;
                $ctrl.loadProductCharacteristic($ctrl.productCharacteristic);
            });
        }

        $ctrl.productCharacteristic = false;

        $ctrl.loadProductCharacteristic({});
    };

    $ctrl.submit = () => {
        
    }

    $ctrl.loadProductCharacteristic = function(productCharacteristic) {
        let values = ProductCharacteristicService.apiResourceToForm(productCharacteristic);

        $ctrl.form = FormBuilderService.build(values, (form) => {
            form.lock();

            let promise;

            if ($ctrl.productCharacteristic) {
                promise = ProductCharacteristicService.update(
                    $ctrl.productCharacteristic.id,
                    form.values
                )
            } else {
                promise = ProductCharacteristicService.store(
                    form.values
                )
            }

            promise.then((res) => {
                if (res.data.data) {
                    pushNotifications.push('success', 'check', 'Saved', 4000);

                    $timeout(function() {
                        document.location = res.data.data.url.admin_list;
                    }, 1000);
                }

                form.unlock();
            }, (res) => {
                form.errors = res.data.errors;
                form.unlock();
            });
        });

        if (!Array.isArray($ctrl.blocks)) {
            $ctrl.blocks = [];
        }
    }

    $ctrl.$onInit = function() {
        $ctrl.productCharacteristic = false;
        
        loadResources();
    };
};

module.exports = {
    bindings: {
        'productCharacteristicId': '<'
    },
    controller: [
        'ProductCharacteristicService',
        'LanguageService',
        'FormBuilderService',
        '$timeout',
        ProductCharacteristicEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/product-characteristic-edit.html'
};